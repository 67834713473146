import { createApp } from 'vue'
import App from './App.vue'
import { TUILogin } from '@tencentcloud/tui-core';
import { framework } from './TUIKit/adapter-vue';
import { genTestUserSig } from './TUIKit';

let url = window.location.href;
let param = url.substring(url.indexOf("?") + 1);
let paramArr = param.split("&");

const paramCode:{[key:string]:string} = {}
for(let i = 0; i < paramArr.length; i++){
	paramCode[paramArr[i].split('=')[0]] = paramArr[i].split('=')[1];
}
if(!paramCode.UserID){
  paramCode.UserID = 'SU157123839'
}
  let UserID = paramCode.UserID;
  let token = paramCode.token;
  TUILogin.login({
    SDKAppID: 1600047747,
    userID: UserID,
    userSig: token,
    useUploadPlugin: true,
    framework,
  }).then(() => {})
  .catch((error) => {
    console.log("我失败啦",error)
  })
createApp(App).mount('#app')
